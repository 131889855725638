@font-face {
  font-family: "NanumSquare";
  src:
    local("NanumSquareL"),
    url("assets/fonts/NanumSquareL.otf") format("OpenType");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "NanumSquare";
  src:
    local("NanumSquareR"),
    url("assets/fonts/NanumSquareR.otf") format("OpenType");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NanumSquare";
  src:
    local("NanumSquareB"),
    url("assets/fonts/NanumSquareB.otf") format("OpenType");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NanumMyeongjo";
  src:
    local("NanumMyeongjo"),
    url("assets/fonts/NanumMyeongjo.otf") format("OpenType");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "NanumMyeongjo";
  src:
    local("NanumMyeongjoBold"),
    url("assets/fonts/NanumMyeongjoBold.otf") format("OpenType");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "NanumMyeongjo";
  src:
    local("NanumMyeongjoExtraBold"),
    url("assets/fonts/NanumMyeongjoExtraBold.otf") format("OpenType");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "NanumSquare";
  src:
    local("NanumSquareRB"),
    url("assets/fonts/NanumSquareEB.otf") format("OpenType");
  font-weight: 800;
  font-style: normal;
}

:root {
  --color-White: #fff;
  --color-Black: #1e1e1e;
  --color-SubBlack: #505050;
  --color-Grey1: #828282;
  --color-Grey2: #b4b4b4;
  --color-Grey3: #e6e6e6;
  --color-Outline: #eeeaea;
  --color-Line: #dcdfe4;
  --color-Key: #a01d0b;
  --color-Footer: #3d3228;
  --color-Background: #ece4dc;
  --color-Box: #2f1e1e;
  --color-Base: #f8f5f1;
  --color-Base2: #e8e0d8;
  --color-BoxLine: #ece6dd;
  --color-InputLine: #bdb6b1;
}

*,
ul,
li,
ol {
  margin: 0;
  padding: 0;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    min-width: 200px;
    min-height: 200px;
    background-color: #ebf0f3;
    border-radius: 12px;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

html {
  width: 100%;
  height: 100%;
  font-size: 10px;
}

body {
  width: 100%;
  background-color: var(--color-BgBlue);
}

#root {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "NanumSquare";
}

p,
span,
li,
label,
input,
textarea,
div {
  font-family: "NanumSquare";
}

h1,
h2,
h3,
h4,
h5,
p,
span {
  line-height: 1.4;
}

a {
  text-decoration: none !important;
}

input:disabled {
  background-color: var(--color-DisabledInput) !important;
  border-color: var(--color-Outline) !important;
}

input[type="checkbox"] {
  display: none;
  & + label {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 22px;
    height: 22px;
    text-align: center;
    cursor: pointer;
  }
  &:checked + label:before {
    content: "";
    display: inline-block;
    color: var(--color-White);
    background-color: var(--color-Key);
    border: none;
    width: 20px;
    height: 20px;
  }
  &:checked + label:after {
    content: "";
    top: 4px;
    right: 9px;
    position: absolute;
    transform: rotate(45deg);
    width: 6px;
    height: 10px;
    border-bottom: solid 2px var(--color-White);
    border-right: solid 2px var(--color-White);
  }
}

select {
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select::-ms-expand {
  display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}
